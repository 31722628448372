import BaseService from './BaseService'

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          let errorMessage = "Bir hata oluştu";
          if (error.response) {
            // Sunucu cevap döndürdüyse
            errorMessage = error.response.data?.detail || "Sunucu hatası";
          } else if (error.request) {
            // İstek yapıldı ama cevap alınamadıysa
            errorMessage = "Sunucudan cevap alınamadı";
          } else {
            // İstek yapılırken bir şeyler ters gittiyse
            errorMessage = error.message || "Bilinmeyen bir hata oluştu";
          }
          reject(errorMessage);
        });
    });
  }
};

export default ApiService;