import ApiService from "./ApiService";

//#region Authentication

export async function loginUser(loginData) {
  const url = "/Auth/Login";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: loginData,
  });
}

export async function registerUser(registerData) {
  const url = "/Auth/Register";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: registerData,
  });
}

export async function refreshToken() {
  const url = "/Auth/RefreshToken";
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function revokeToken(token) {
  const url = "/Auth/RevokeToken";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: token,
  });
}

export async function forgotPassword(forgotPassword) {
  const url = "/Auth/ForgotPassword";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: forgotPassword,
  });
}

export async function resetPassword(password, confirmPassword, token) {
  const url = "/Auth/ResetPassword"; // Backend endpoint URL'si

  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: {
      password, // Yeni şifre
      confirmPassword, // Onay şifresi
      token, // Şifre sıfırlama token'ı
    },
  });
}

//#endregion

//#region Email Authenticator

export async function enableEmailAuthenticator() {
  const url = "/Auth/EnableEmailAuthenticator";
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function verifyEmailAuthenticator(activationKey) {
  const url = `/Auth/VerifyEmailAuthenticator?ActivationKey=${activationKey}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

//#endregion

//#region OTP Authenticator

export async function enableOtpAuthenticator() {
  const url = "/Auth/EnableOtpAuthenticator";
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function verifyOtpAuthenticator(authenticatorCode) {
  const url = "/Auth/VerifyOtpAuthenticator";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: authenticatorCode,
  });
}

//#endregion

//#region Koha Login

export async function kohaLogin(loginData) {
  const url = "/Auth/KohaLogin";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: loginData,
  });
}

//#endregion
