import ApiService from "./ApiService";

//#region Operation Claims

export async function addOperationClaim(operationClaimData) {
  const url = "/OperationClaims";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: operationClaimData,
  });
}

export async function updateOperationClaim(operationClaimData) {
  const url = "/OperationClaims";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: operationClaimData,
  });
}

export async function getOperationClaimsPagination(pageIndex, pageSize) {
  const url = `/OperationClaims?PageIndex=${pageIndex}&PageSize=${pageSize}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function deleteOperationClaim(operationClaimData) {
  const url = `/OperationClaims`;
  return ApiService.fetchData({
    url: url,
    method: "DELETE",
    data: operationClaimData,
  });
}

export async function getOperationClaimById(operationClaimId) {
  const url = `/OperationClaims/${operationClaimId}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

//#endregion
