import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { loginUser, registerUser } from "services/AuthService";
import { getUserOperationClaimById } from "services/UserService";
import { getOperationClaimById } from "services/OperationClaimService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { getUserFromAuth } from "services/UserService";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { toast, Notification } from "components/ui";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await loginUser(values);
      console.log(resp);
      if (resp.data) {
        dispatch(onSignInSuccess(resp.data.accessToken.token));
        localStorage.setItem("token", resp.data.accessToken.token);
        const user = await getUserFromAuth(values);
        console.log(user);
        if (user.data.data) {
          const userclaim = await getUserOperationClaimById(user.data.data.id);
          if (userclaim.data.data) {
            const userClaimName = await getOperationClaimById(
              userclaim.data.data.operationClaimId
            );
            if (userClaimName) {
              dispatch(
                setUser(
                  resp.data.user || {
                    avatar: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    identityCode: "",
                    countryCode: "",
                    phoneNumber: "",
                    authority: [userClaimName.data.data.name],
                  }
                )
              );
            }
          }
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await registerUser(values);
      if (resp.data) {
        toast.push(
          <Notification title="Başarılı" type="success">
            Hesabınız Başarıyla Oluşturulmuştur.
          </Notification>,
          {
            placement: 'top-end',
            autoClose: 5000,
          }
        );
        // const { token } = resp.data;
        // dispatch(onSignInSuccess(token));
        //dispatch(onSignInSuccess(resp.data.token));
        // localStorage.setItem("token", resp.data.token);
        // const user = await getUserFromAuth(values);
        // console.log(user);
        // if (user.data.data) {
        //   // const userclaim = await getUserOperationClaimById(user.data.data.id);
        //   // if (userclaim.data.data) {
        //   //   const userClaimName = await getOperationClaimById(
        //   //     userclaim.data.data.operationClaimId
        //   //   );
        //   //   if (userClaimName) {
        //   //     dispatch(
        //   //       setUser(
        //   //         resp.data.user || {
        //   //           avatar: "",
        //   //           email: "",
        //   //           firstName: "",
        //   //           lastName: "",
        //   //           identityCode: "",
        //   //           countryCode: "",
        //   //           phoneNumber: "",
        //   //           authority: [userClaimName.data.data.name],
        //   //         }
        //   //       )
        //   //     );
        //   //   }
        //   // }
        // }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.unAuthenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.removeItem("token");
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    //await apiSignOut()
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
