import ApiService from "./ApiService";

//#region Users

export async function getUsersPagination(pageIndex, pageSize) {
  const url = `/Users?PageIndex=${pageIndex}&PageSize=${pageSize}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function getUserById(userId) {
  const url = `/Users/${userId}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function getUserFromAuth() {
  const url = "/Users/GetFromAuth";
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function addUser(userData) {
  const url = "/Users";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: userData,
  });
}

export async function updateUser(userData) {
  const url = "/Users";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: userData,
  });
}

export async function suspendUser(suspendData) {
  const url = "/Users/SuspendUser";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: suspendData,
  });
}

export async function unsuspendUser(unsuspendData) {
  const url = "/Users/unSuspendUser";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: unsuspendData,
  });
}

export async function deleteUser(userId) {
  const url = `/Users`;
  return ApiService.fetchData({
    url: url,
    method: "DELETE",
    data: userId,
  });
}

export async function updateUserFromAuth(userData) {
  const url = "/Users/FromAuth";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: userData,
  });
}

export async function getUserQrCode() {
  const url = "/Users/GenerateUserQrCode";
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}
//#endregion

//#region User Operation Claims

export async function getUserOperationClaimsPagination(pageIndex, pageSize) {
  const url = `/UserOperationClaims?PageIndex=${pageIndex}&PageSize=${pageSize}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function addUserOperationClaim(userOperationClaimData) {
  const url = "/UserOperationClaims";
  return ApiService.fetchData({
    url: url,
    method: "POST",
    data: userOperationClaimData,
  });
}

export async function updateUserOperationClaim(userOperationClaimData) {
  const url = "/UserOperationClaims";
  return ApiService.fetchData({
    url: url,
    method: "PUT",
    data: userOperationClaimData,
  });
}

export async function deleteUserOperationClaim(userOperationClaimData) {
  const url = `/UserOperationClaims`;
  return ApiService.fetchData({
    url: url,
    method: "DELETE",
    data: userOperationClaimData,
  });
}

export async function getUserOperationClaimById(userOperationClaimId) {
  const url = `/UserOperationClaims/${userOperationClaimId}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

export async function getUserOperationClaimByUserId(userId) {
  const url = `/UserOperationClaims/GetByUserId?UserId=${userId}`;
  return ApiService.fetchData({
    url: url,
    method: "GET",
  });
}

//#endregion
